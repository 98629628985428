import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./routers";

function App() {
  return (
    <>
      <ToastContainer autoClose={2000} />
      <Routers />
    </>
  );
}

export default App;
