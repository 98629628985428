import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import SavingsTable from "../savingsTable";
import MoneyMarketTable from "../moneyMarketTable";
import InterestCheckingTable from "../interestChecking";
import CertificatesDepositTable from "../certificatesDepositTable";
import { getCompareTable } from "../../../../store/compare-slice";
function CompareSearch() {
  const [selectedOption, setSelectedOption] = useState("Savings");
  const [relationshipTier, setRelationshipTier] = useState(null);
  const [tier, setTier] = useState(null);
  const [search, setSearch] = useState("Savings");
  const dispatch = useDispatch();
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setRelationshipTier(null);
  };
  const handleSearch = () => {
    setTier(relationshipTier === null ? "cd_less_than_year" : relationshipTier);
    setSearch(selectedOption);
    let urlParams;
    if (selectedOption === "CD") {
      if (!relationshipTier) {
        setRelationshipTier("cd_less_than_year");
      }
      urlParams = `?request_for=${selectedOption}&cd_limit=${relationshipTier || ""}`;
    } else {
      urlParams = `?request_for=${selectedOption}&tier=${relationshipTier || ""}`;
    }
    dispatch(getCompareTable(urlParams));
  };
  const handleRelationshipTierChange = (event) => {
    setRelationshipTier(event.target.value);
  };
  useEffect(() => {
    const urlParams = `?request_for=${selectedOption}&tier=${relationshipTier || ""}`;
    dispatch(getCompareTable(urlParams));
  }, [dispatch]);
  const renderTableComponent = () => {
    if (search === "Savings") {
      return <SavingsTable selected={relationshipTier} option={search} />;
    } else if (search === "Money Markets") {
      return <MoneyMarketTable selected={relationshipTier} option={search} />;
    } else if (search === "Checking") {
      return <InterestCheckingTable selected={relationshipTier} option={search} />;
    } else if (search === "CD") {
      return <CertificatesDepositTable selected={tier} option={search} />;
    }
    return <SavingsTable />;
  };
  const renderRelationshipTierOptions = () => {
    if (selectedOption === "CD") {
      return (
        <>
          <option value="cd_less_than_year">CDs less than 1 year</option>
          <option value="cd_greater_than_year">CD greater than 1 year</option>
        </>
      );
    } else {
      return (
        <>
          <option selected={!relationshipTier} value="">
            Relationship Tier
          </option>
          <option value="Tier 1">Tier 1</option>
          <option value="Tier 2">Tier 2</option>
          <option value="Tier 3">Tier 3</option>
        </>
      );
    }
  };
  return (
    <div className="shadow-box pb-2">
      <div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="form-group">
              <Form.Select
                aria-label="Default select example"
                className="w-100"
                onChange={handleOptionChange}
                value={selectedOption}>
                <option value="Savings">Savings</option>
                <option value="Money Markets">Money Markets</option>
                <option value="Checking">Interest Checking</option>
                <option value="CD">Certificates of Deposit</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="form-group">
              <input type="text" className="form-control" disabled value={11563}></input>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="form-group">
              <Form.Select
                aria-label="Default select example"
                className="w-100"
                onChange={handleRelationshipTierChange}
                value={relationshipTier}
                disabled={!selectedOption}>
                {renderRelationshipTierOptions()}
              </Form.Select>
            </div>
          </div>
          <div className="col-xl-2 col-md-6">
            <div className="form-group">
              <button className="fill-btn w-100" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {renderTableComponent()}
    </div>
  );
}

export default CompareSearch;
