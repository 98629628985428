/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import positionIcon from "../../assets/images/comp-position-icon.svg";
import RangeIcon from "../../assets/images/range-icon.svg";
import AvgRateIcon from "../../assets/images/avg-rate-icon.svg";
import TopMoversIcon from "../../assets/images/top-movers-icon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDashboard } from "../../store/dashboard-slice";

function Dashboard() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDashboardData = useSelector((state) => state?.dashboard?.getDashboardData);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    
    if (!token) {
      toast.error("Session expired login again");
      navigate("/");
    }
  }, [navigate]);


  const calculateWidth = (bankRate, averageRate) => {
    if (bankRate > averageRate) {
      return { width: "95%", arrowColor: "arw-green" };
    } else if (bankRate === averageRate) {
      return { width: "50%", arrowColor: "arw-yellow" };
    } else {
      return { width: "20%", arrowColor: "arw-red" };
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-4 col-md-6">
          <div className="shadow-box pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="title5 font-weight-bold">Interest Checking</h4>
              <h6 className="title6 font-weight-semibold color-gray">
                {getDashboardData?.response?.total_bank_count} Banks
              </h6>
            </div>
            <div className="percent-bar-area">
              <div className="inn">
                <div className="line"></div>
                <div
                  className="arrow-count"
                  style={{
                    width: calculateWidth(
                      getDashboardData?.response?.Checking?.my_bank?.rate,
                      getDashboardData?.response?.Checking?.average_rate
                    )?.width
                  }}>
                  <div className="arrow-count-inn">
                    <h6 className="title6 font-weight-semibold cnt">
                      {getDashboardData?.response?.Checking?.my_bank?.rate ? 
                        `${getDashboardData?.response?.Checking?.my_bank?.rate}%` : 
                        ""}
                    </h6>
                    <div
                      className={`arrow-triangle ${calculateWidth(
                        getDashboardData?.response?.Checking?.my_bank?.rate,
                        getDashboardData?.response?.Checking?.average_rate
                      ).arrowColor}`}
                    ></div>
                    <label className="font-weight-semibold">
                      {getDashboardData?.response?.Checking?.my_bank?.bank}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={positionIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0"  style={{ fontSize: "smaller" }}>
                      {" "}
                      {getDashboardData?.response?.Checking?.comp_position
                        ? `${getDashboardData?.response?.Checking?.comp_position}`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Comp. Position</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={RangeIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Checking?.rate_range ? (
                        <>
                          {getDashboardData?.response?.Checking?.rate_range?.low_rate === 0
                            ? 0
                            : getDashboardData?.response?.Checking?.rate_range?.low_rate || "-"}
                            &nbsp;
                            -
                           &nbsp;
                          {getDashboardData?.response?.Checking?.rate_range?.high_rate === 0 
                            ? 0
                            : getDashboardData?.response?.Checking?.rate_range?.high_rate || "-"}
                %
                        </>
                      ) : (
                        "-"
                      )}
                    </h6>
                    <label className="font-weight-bold color-gray">Range</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={AvgRateIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Checking?.average_rate
                        ? `${getDashboardData?.response?.Checking?.average_rate}%`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Avg Rate</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={TopMoversIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0 text-break" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Checking?.top_mover ? (
                        <>
                          {getDashboardData?.response?.Checking?.top_mover?.bank || "-"}
                          &nbsp;
                        +
                        &nbsp;
                          {getDashboardData?.response?.Checking?.top_mover?.rate != null &&
                        getDashboardData?.response?.Checking?.top_mover?.rate !== ""
                            ? `${getDashboardData?.response?.Checking?.top_mover?.rate}%`
                            : "-"}
                        </>
                      ) : (
                        "-"
                      )}
                    
                    </h6>
                    <label className="font-weight-bold color-gray">Top Movers</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="shadow-box pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="title5 font-weight-bold">Savings</h4>
              <h6 className="title6 font-weight-semibold color-gray">
                {getDashboardData?.response?.total_bank_count} Banks
              </h6>
            </div>
            <div className="percent-bar-area">
              <div className="inn">
                <div className="line"></div>
                <div
                  className="arrow-count"
                  style={{
                    width: calculateWidth(
                      getDashboardData?.response?.Savings?.my_bank?.rate,
                      getDashboardData?.response?.Savings?.average_rate
                    )?.width
                  }}>
                  <div className="arrow-count-inn">
                    <h6 className="title6 font-weight-semibold cnt">
                      {getDashboardData?.response?.Savings?.my_bank?.rate ? 
                        `${getDashboardData?.response?.Savings?.my_bank?.rate}%` : 
                        ""}
                    </h6>
                    <div
                      className={`arrow-triangle ${calculateWidth(
                        getDashboardData?.response?.Savings?.my_bank?.rate,
                        getDashboardData?.response?.Savings?.average_rate
                      ).arrowColor}`}
                    ></div>
                    <label className="font-weight-semibold">
                      {getDashboardData?.response?.Savings?.my_bank?.bank}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={positionIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {" "}
                      {getDashboardData?.response?.Savings?.comp_position
                        ? `${getDashboardData?.response?.Savings?.comp_position}`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Comp. Position</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={RangeIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Savings?.rate_range ? (
                        <>
                          {getDashboardData?.response?.Savings?.rate_range?.low_rate === 0 
                            ? 0
                            : getDashboardData?.response?.Savings?.rate_range?.low_rate || "-"}
                            &nbsp;
                            -
                           &nbsp;
                          {getDashboardData?.response?.Savings?.rate_range?.high_rate === 0
                            ? 0
                            : getDashboardData?.response?.Savings?.rate_range?.high_rate || "-"}
                  %
                        </>
                      ) : (
                        "-"
                      )}
                    </h6>
                    <label className="font-weight-bold color-gray">Range</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={AvgRateIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Savings?.average_rate
                        ? `${getDashboardData?.response?.Savings?.average_rate}%`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Avg Rate</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={TopMoversIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0 text-break" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.Savings?.top_mover ? (
                        <>
                          {getDashboardData?.response?.Savings?.top_mover?.bank || "-"}
                          &nbsp;
                           +
                          &nbsp;
                          {getDashboardData?.response?.Savings?.top_mover?.rate != null &&
                      getDashboardData?.response?.Savings?.top_mover?.rate !== "" &&
                      getDashboardData?.response?.Savings?.top_mover?.rate != undefined 

                            ? `${getDashboardData?.response?.Savings?.top_mover?.rate}%`
                            : "-"}
                        </>
                      ) : (
                        "-"
                      )}
                    </h6>
                    <label className="font-weight-bold color-gray">Top Movers</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-6">
          <div className="shadow-box pb-0">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="title5 font-weight-bold">Money Market</h4>
              <h6 className="title6 font-weight-semibold color-gray">
                {getDashboardData?.response?.total_bank_count} Banks
              </h6>
            </div>
            <div className="percent-bar-area">
              <div className="inn">
                <div className="line"></div>
                <div
                  className="arrow-count"
                  style={{
                    width: calculateWidth(
                      getDashboardData?.response?.money_market?.my_bank?.rate,
                      getDashboardData?.response?.money_market?.average_rate
                    )?.width
                  }}>
                  <div className="arrow-count-inn">
                    <h6 className="title6 font-weight-semibold cnt">
                      {getDashboardData?.response?.money_market?.my_bank?.rate ? 
                        `${getDashboardData?.response?.money_market?.my_bank?.rate}%` : 
                        ""}
                    </h6>
                    <div
                      className={`arrow-triangle ${calculateWidth(
                        getDashboardData?.response?.money_market?.my_bank?.rate,
                        getDashboardData?.response?.money_market?.average_rate
                      ).arrowColor}`}
                    ></div>
                    <label className="font-weight-semibold">
                      {getDashboardData?.response?.money_market?.my_bank?.bank}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={positionIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {" "}
                      {getDashboardData?.response?.money_market?.comp_position
                        ? `${getDashboardData?.response?.money_market?.comp_position}`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Comp. Position</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={RangeIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.money_market?.rate_range ? (
                        <>
                          {getDashboardData?.response?.money_market?.rate_range?.low_rate === 0
                            ? 0
                            : getDashboardData?.response?.money_market?.rate_range?.low_rate || "-"}
                            &nbsp;
                            -
                           &nbsp;
                          {getDashboardData?.response?.money_market?.rate_range?.high_rate === 0 
                            ? 0
                            : getDashboardData?.response?.money_market?.rate_range?.high_rate || "-"}
                    %
                        </>
                      ) : (
                        "-"
                      )}
                    </h6>
                    <label className="font-weight-bold color-gray">Range</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={AvgRateIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                      {getDashboardData?.response?.money_market?.average_rate
                        ? `${getDashboardData?.response?.money_market?.average_rate}%`
                        : "-"}
                    </h6>
                    <label className="font-weight-bold color-gray">Avg Rate</label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon-head-cont-sml">
                  <img src={TopMoversIcon} />
                  <div>
                    <h6 className="title6 font-weight-semibold mb-0 text-break" style={{ fontSize: "smaller" }}> 
                      {getDashboardData?.response?.money_market?.top_mover ? (
                        <>
                          {getDashboardData?.response?.money_market?.top_mover?.bank || "-"}
                          &nbsp;
                          +
                         &nbsp;
                          {getDashboardData?.response?.money_market?.top_mover?.rate != null &&
                    getDashboardData?.response?.money_market?.top_mover?.rate !== ""
                            ? `${getDashboardData?.response?.money_market?.top_mover?.rate}%`
                            : "-"}
                        </>
                      ) : (
                        "-"
                      )}
                    </h6>
                    <label className="font-weight-bold color-gray">Top Movers</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="shadow-box pb-0 mb-0">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="title font-weight-bold">Certificates of Deposit</h4>
          <h6 className="title6 font-weight-semibold color-gray">
            {getDashboardData?.response?.total_bank_count} Banks
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="shadow-box shadow-dark pb-0">
              <h5 className="title5 font-weight-bold color-gray">CD's less than 1 Year</h5>
              <div className="percent-bar-area">
                <div className="inn">
                  <div className="line"></div>
                  <div
                    className="arrow-count"
                    style={{
                      width: calculateWidth(
                        getDashboardData?.response?.CD_less_than_year?.my_bank?.rate,
                        getDashboardData?.response?.CD_less_than_year?.average_rate
                      )?.width
                    }}>
                    <div className="arrow-count-inn">
                      <h6 className="title6 font-weight-semibold cnt">
                        {getDashboardData?.response?.CD_less_than_year?.my_bank?.rate ? 
                          `${getDashboardData.response.CD_less_than_year.my_bank.rate}%` : 
                          " "}
                      </h6>
                      <div
                        className={`arrow-triangle ${calculateWidth(
                          getDashboardData?.response?.CD_less_than_year?.my_bank?.rate,
                          getDashboardData?.response?.CD_less_than_year?.average_rate
                        ).arrowColor}`}
                      ></div>
                      <label className="font-weight-semibold">
                        {getDashboardData?.response?.CD_less_than_year?.my_bank?.bank}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={positionIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {" "}
                        {getDashboardData?.response?.CD_less_than_year?.comp_position
                          ? `${getDashboardData?.response?.CD_less_than_year?.comp_position}`
                          : "-"}
                      </h6>
                      <label className="font-weight-bold color-gray">Comp. Position</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={RangeIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {getDashboardData?.response?.CD_less_than_year?.rate_range ? (
                          <>
                            {getDashboardData?.response?.CD_less_than_year?.rate_range?.low_rate === 0 
                              ? 0
                              : getDashboardData?.response?.CD_less_than_year?.rate_range?.low_rate || "-"}
                              &nbsp;
                              -
                             &nbsp;
                            {getDashboardData?.response?.CD_less_than_year?.rate_range?.high_rate === 0
                              ? 0
                              : getDashboardData?.response?.CD_less_than_year?.rate_range?.high_rate || "-"}
                        %
                          </>
                        ) : (
                          "-"
                        )}
                      </h6>
                      <label className="font-weight-bold color-gray">Range</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={AvgRateIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {" "}
                        {getDashboardData?.response?.CD_less_than_year?.average_rate
                          ? `${getDashboardData?.response?.CD_less_than_year?.average_rate}%`
                          : "-"}
                      </h6>
                      <label className="font-weight-bold color-gray">Avg Rate</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={TopMoversIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0 text-break" style={{ fontSize: "smaller" }}>
                        {getDashboardData?.response?.CD_less_than_year?.top_mover ? (
                          <>
                            {getDashboardData?.response?.CD_less_than_year?.top_mover?.bank || "-"}
                            &nbsp;
                            +
                           &nbsp;
                            {getDashboardData?.response?.CD_less_than_year?.top_mover?.rate != null &&
                    getDashboardData?.response?.CD_less_than_year?.top_mover?.rate !== ""
                              ? `${getDashboardData?.response?.CD_less_than_year?.top_mover?.rate}%`
                              : "-"}
                          </>
                        ) : (
                          "-"
                        )}
                      </h6>
                      <label className="font-weight-bold color-gray">Top Movers</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="shadow-box shadow-dark pb-0">
              <h5 className="title5 font-weight-bold color-gray">CD's greater than 1 Year</h5>
              <div className="percent-bar-area">
                <div className="inn">
                  <div className="line"></div>
                  <div
                    className="arrow-count"
                    style={{
                      width: calculateWidth(
                        getDashboardData?.response?.CD_grater_than_year?.my_bank?.rate,
                        getDashboardData?.response?.CD_grater_than_year?.average_rate
                      )?.width
                    }}>
                    <div className="arrow-count-inn">
                      <h6 className="title6 font-weight-semibold cnt">
                        {getDashboardData?.response?.CD_grater_than_year?.my_bank?.rate ? 
                          `${getDashboardData?.response?.CD_grater_than_year?.my_bank?.rate}%` : 
                          ""}
                      </h6>
                      <div
                        className={`arrow-triangle ${calculateWidth(
                          getDashboardData?.response?.CD_grater_than_year?.my_bank?.rate,
                          getDashboardData?.response?.CD_grater_than_year?.average_rate
                        ).arrowColor}`}
                      ></div>
                      <label className="font-weight-semibold">
                        {getDashboardData?.response?.CD_grater_than_year?.my_bank?.bank}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={positionIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {getDashboardData?.response?.CD_grater_than_year?.comp_position
                          ? `${getDashboardData?.response?.CD_grater_than_year?.comp_position}`
                          : "-"}
                      </h6>
                      <label className="font-weight-bold color-gray">Comp. Position</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={RangeIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {getDashboardData?.response?.CD_grater_than_year?.rate_range ? (
                          <>
                            {getDashboardData?.response?.CD_grater_than_year?.rate_range?.low_rate === 0 
                              ? 0
                              : getDashboardData?.response?.CD_grater_than_year?.rate_range?.low_rate || "-"}
                              &nbsp;
                              -
                             &nbsp;
                            {getDashboardData?.response?.CD_grater_than_year?.rate_range?.high_rate === 0 
                              ? 0
                              : getDashboardData?.response?.CD_grater_than_year?.rate_range?.high_rate || "-"}
                          %
                          </>
                        ) : (
                          "-"
                        )}
                       
                      </h6>
                      <label className="font-weight-bold color-gray">Range</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={AvgRateIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0" style={{ fontSize: "smaller" }}>
                        {" "}
                        {getDashboardData?.response?.CD_grater_than_year?.average_rate
                          ? `${getDashboardData?.response?.CD_grater_than_year?.average_rate}%`
                          : "-"}
                      </h6>
                      <label className="font-weight-bold color-gray">Avg Rate</label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="icon-head-cont-sml">
                    <img src={TopMoversIcon} />
                    <div>
                      <h6 className="title6 font-weight-semibold mb-0 text-break" style={{ fontSize: "smaller" }}>
                        {getDashboardData?.response?.CD_grater_than_year?.top_mover ? (
                          <>
                            {getDashboardData?.response?.CD_grater_than_year?.top_mover?.bank || "-"}
                            &nbsp;
                            +
                           &nbsp;
                            {getDashboardData?.response?.CD_grater_than_year?.top_mover?.rate != null &&
                  getDashboardData?.response?.CD_grater_than_year?.top_mover?.rate !== ""
                              ? `${getDashboardData?.response?.CD_grater_than_year?.top_mover?.rate}%`
                              : "-"}
                          </>
                        ) : (
                          "-"
                        )}
                      </h6>
                      <label className="font-weight-bold color-gray">Top Movers</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
