import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../../../../../store/auth-slice";

function LogOutModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleModalClose = () => {
    props.onHide();
  };

  const token = localStorage.getItem("token");

  const handleLogout = () => {
    dispatch(logOutAction(token));
    localStorage.clear();
    toast.success("You are logged out! Please sign in to continue.");
    navigate("/", { replace: true });
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-normal"
        backdrop="static"
        keyboard={false}
        onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center w-100">
            Log Out
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>Are you sure you want to log out of the application?</p>
          <div className="pt-3 d-flex justify-content-center">
            <button type="button" className="fill-btn btn-red mr-4" onClick={props.onHide}>
              Cancel
            </button>
            <button type="submit" className="fill-btn" onClick={handleLogout}>
              Yes, Logout
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LogOutModal;
