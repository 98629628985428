import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRouter = ({ layout: Layout }) => {
  const auth = useSelector((state) => state.auth);

  const isAuthenticated = auth?.isLoggedIn;
  return isAuthenticated ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRouter;
