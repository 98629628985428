import React from "react";
import PropTypes from "prop-types";
import Header from "../../modules/shared/components/header";
import Leftidebar from "../../modules/shared/components/leftSidebar";
import ContentArea from "../../modules/shared/components/contentArea";

const PageWrapper = (props) => {
  return (
    <div className="page-main-wrapper">
      <Header />
      <div className="main-wrapper">
        <Leftidebar />

        <ContentArea>
          <div className="page-contents">{props.children}</div>
        </ContentArea>
      </div>
    </div>
  );
};
PageWrapper.propTypes = {
  children: PropTypes.node
};
export default PageWrapper;
