import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import profImg from "../../assets/images/no-avatar.png";
import locationIcon from "../../assets/images/location-gray-icon.svg";
import competitorIcon from "../../assets/images/competitor-icon.svg";
import "./myProfile.css";
import "react-toastify/dist/ReactToastify.css";
import EditPersonalInfoModal from "./components/editPersonalInfoModal";
import AddCompetitorModal from "./components/addCompetitorModal";
import { getProfile, updateBank } from "../../store/Profile-slice";
import { getBank, deletCompetitor, editCompetitor, getBankByParam } from "../../store/bank-slice";

function MyProfile() {
  const [editPersonalInfoModalShow, setEditPersonalInfoModalShow] = useState(false);
  const [addCompetitorModalShow, setAddCompetitorModalShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editCompitior, setEditCompititor] = useState(false);
  const [isUpdatingBank, setIsUpdatingBank] = useState(false);
  const [isDeletingCompetitor, setIsDeletingCompetitor] = useState(false);
  const [, setBankName] = useState(getProfileData?.response?.bank && getProfileData.response.bank);
  const [competitors, setCompetitors] = useState(getProfileData?.response?.competitors);

  const { id } = useSelector((state) => state?.auth);
  const getProfileData = useSelector((state) => state?.profile?.getProfileData);
  const bank = useSelector((state) => state?.bank?.getBankData);
  const bankData = bank?.response;
  const currentBank = Array.isArray(bankData)
    ? bankData?.filter((item) => item?.bank_name === getProfileData?.response?.bank)[0]?.id
    : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (getProfileData?.response?.competitors) {
      setCompetitors(getProfileData?.response?.competitors);
    }
  }, [getProfileData?.response?.competitors]);
  const urlParams = "?request_for=competitor";
  useEffect(() => {
    dispatch(getProfile(id));
    dispatch(getBank());
    setBankName(getProfileData?.response?.bank);
  }, [dispatch, id]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelChange = () => {
    setBankName(getProfileData?.response?.bank);
    setEditMode(false);
  };

  const handleUpdateBank = async (values, setSubmitting) => {
    if (isUpdatingBank) {
      return;
    }
    setIsUpdatingBank(true);

    const bankId = values.bank;
    const data = {
      bank: bankId
    };

    try {
      await dispatch(updateBank({ data }));
      setBankName(bankId);

      dispatch(getProfile(id));
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
    setIsUpdatingBank(false);
    setEditMode(false);
  };

  const handleEditCompititor = () => {
    setEditCompititor(true);
  };
  const handleCancelCompititor = () => {
    setEditCompititor(false);
  };
  const handleSaveCompititor = async () => {
    setEditCompititor(false);
    for (const competitor of competitors) {
      const compid = competitor.id;
      const bankId = competitor.bank_id;
      const newWeightage = competitor.weightage;
      const data = {
        bank: bankId,
        weightage: newWeightage
      };

      await dispatch(editCompetitor({ compid, data }));
    }

    dispatch(getProfile(id));
  };

  const handleDeleteCompetitor = (competitorId) => {
    if (isDeletingCompetitor) {
      return;
    }
    setIsDeletingCompetitor(true);

    dispatch(deletCompetitor(competitorId))
      .then(() => {
        setCompetitors((prevCompetitors) =>
          prevCompetitors.filter((competitor) => competitor.id !== competitorId)
        );
      })
      .then(() => {
        dispatch(getBankByParam(urlParams));
        setIsDeletingCompetitor(false);
      });
  };

  return (
    <>
      <div className="shadow-box mb-0">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="title5 font-weight-bold">Personal Info</h5>
            <button className="border-btn mb-3" onClick={() => setEditPersonalInfoModalShow(true)}>
              Edit
            </button>
          </div>
          <div className="prsnl-info-area">
            <div className="prof-img">
              {getProfileData?.response?.profile_pic ? (
                <img src={getProfileData.response.profile_pic} />
              ) : (
                <img src={profImg} alt="Default Profile" />
              )}
            </div>
            <div className="prof-dtl">
              <h6 className="title6 font-weight-semibold mb-2">
                {getProfileData?.response?.first_name
                  ? getProfileData.response.first_name.charAt(0).toUpperCase() +
                    getProfileData.response.first_name.slice(1)
                  : ""}{" "}
                {getProfileData?.response?.last_name || ""}
              </h6>
              <p className="font-weight-semibold color-light-gray mb-2">
                <img className="mr-1" src={locationIcon} />
                {getProfileData?.response?.location}
              </p>
              <label className="font-weight-medium mb-0 text-break">
                {getProfileData?.response?.email}
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <Formik
            initialValues={{
              bank: currentBank || ""
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => handleUpdateBank(values, setSubmitting)}
            validate={(values) => {
              const errors = {};
              if (!values.bank) {
                errors.bank = "Bank is not selected";
              }
              return errors;
            }}>
            {({ values, handleSubmit, handleChange }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="title5 font-weight-bold">My Bank</h5>
                  {!editMode ? (
                    <button className="border-btn mb-3" onClick={handleEditClick}>
                      Edit
                    </button>
                  ) : null}
                  {editMode ? (
                    <div>
                      <button className="fill-btn btn-red mb-3 mr-3" onClick={handleCancelChange}>
                        Cancel
                      </button>
                      <button className="fill-btn mb-3" type="submit">
                        Save
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {editMode ? (
                    <div className="form-group  mb-0 col-md-6">
                      <BootstrapForm.Select
                        aria-label="Default select example"
                        className="w-100"
                        id="bank"
                        name="bank"
                        value={values.bank}
                        onChange={handleChange}>
                        <option disabled value="">
                          Select Bank
                        </option>
                        {bank?.response.map((bankItem) => (
                          <option key={bankItem.id} value={bankItem.id}>
                            {bankItem.bank_name}
                          </option>
                        ))}
                      </BootstrapForm.Select>
                      <ErrorMessage
                        name="bank"
                        component="div"
                        className="error-message"
                        style={{ color: "red" }}
                      />
                    </div>
                  ) : (
                    <div className="form-group mb-0 col-md-6 ">
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={getProfileData?.response?.bank}
                      />
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <hr />
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="title5 font-weight-bold">My Competitors</h5>
            <div>
              {!editCompitior ? (
                <button className="border-btn mb-3" onClick={handleEditCompititor}>
                  Edit
                </button>
              ) : null}
              {editCompitior ? (
                <div>
                  <button className="fill-btn btn-red mb-3 mr-3" onClick={handleCancelCompititor}>
                    Cancel
                  </button>
                  <button type="submit" className="fill-btn mb-3" onClick={handleSaveCompititor}>
                    Save
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="competitor-box-area d-flex flex-wrap">
            {competitors?.map((competitor, index) => (
              <div
                className={editCompitior ? "competitor-box edit-competitor" : "competitor-box"}
                key={index}>
                <div className="shadow-box mb-0 h-100">
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <img src={competitorIcon} />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-end icon-btn-out">
                        <button
                          className="icon-btn"
                          onClick={() => handleDeleteCompetitor(competitor.id)}>
                          <svg
                            id="Layer_2"
                            data-name="Layer 2"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32">
                            <g id="invisible_box" data-name="invisible box">
                              <rect
                                id="Rectangle_2902"
                                data-name="Rectangle 2902"
                                width="32"
                                height="32"
                                fill="none"
                              />
                            </g>
                            <g
                              id="icons_Q2"
                              data-name="icons Q2"
                              transform="translate(1.333 1.333)">
                              <path
                                id="Path_8252"
                                data-name="Path 8252"
                                d="M16.667,4.667a12,12,0,1,1-12,12,12,12,0,0,1,12-12m0-2.667A14.6,14.6,0,1,0,27.057,6.276,14.667,14.667,0,0,0,16.667,2Z"
                                transform="translate(-2 -2)"
                                fill="#0073cf"
                              />
                              <path
                                id="Path_8253"
                                data-name="Path 8253"
                                d="M22.867,20.986,26.6,17.32a1.4,1.4,0,0,0,.133-1.8,1.267,1.267,0,0,0-2-.133L21,19.12l-3.733-3.733a1.267,1.267,0,0,0-2,.133,1.4,1.4,0,0,0,.133,1.8l3.733,3.667L15.4,24.653a1.4,1.4,0,0,0-.133,1.8,1.267,1.267,0,0,0,2,.133L21,22.853l3.733,3.733a1.267,1.267,0,0,0,2-.133,1.4,1.4,0,0,0-.133-1.8Z"
                                transform="translate(-6.334 -6.32)"
                                fill="#0073cf"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                      <h6 className="title6 font-weight-semibold mb-2">{competitor?.bank}</h6>
                      <label className="font-weight-medium mb-0 wtg">
                        <span className="color-gray">Weighting: </span>
                        {competitor?.weightage === 1 && "Low"}
                        {competitor?.weightage === 2 && "Medium"}
                        {competitor?.weightage === 3 && "High"}
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-0 mt-3 d-none">
                    <label>Weighting</label>
                    <select
                      value={competitor?.weightage}
                      className="form-control"
                      readOnly={!editCompitior}
                      id="weightage"
                      name="weightage"
                      onChange={(e) => {
                        const newWeightage = e.target.value;
                        setCompetitors((prevCompetitors) =>
                          prevCompetitors.map((comp) => {
                            if (comp.id === competitor?.id) {
                              return {
                                ...comp,
                                weightage: newWeightage
                              };
                            }
                            return comp;
                          })
                        );
                      }}>
                      <option value="1">Low</option>
                      <option value="2">Medium</option>
                      <option value="3">High</option>
                    </select>
                  </div>
                </div>
              </div>
            ))}

            <div className="competitor-box">
              <button className="add-btn" onClick={() => setAddCompetitorModalShow(true)}>
                <svg
                  id="Layer_2"
                  data-name="Layer 2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48">
                  <g id="invisible_box" data-name="invisible box">
                    <rect
                      id="Rectangle_2895"
                      data-name="Rectangle 2895"
                      width="48"
                      height="48"
                      fill="none"
                    />
                    <rect
                      id="Rectangle_2896"
                      data-name="Rectangle 2896"
                      width="48"
                      height="48"
                      fill="none"
                    />
                    <rect
                      id="Rectangle_2897"
                      data-name="Rectangle 2897"
                      width="48"
                      height="48"
                      fill="none"
                    />
                  </g>
                  <g id="icons_Q2" data-name="icons Q2">
                    <g id="Group_6760" data-name="Group 6760">
                      <path
                        id="Path_8245"
                        data-name="Path 8245"
                        d="M24,2A21.9,21.9,0,1,0,39.586,8.414,22,22,0,0,0,24,2Zm0,40A18,18,0,1,1,42,24,18,18,0,0,1,24,42Z"
                        fill="#0073cf"
                      />
                      <path
                        id="Path_8246"
                        data-name="Path 8246"
                        d="M34,22H26V14a2,2,0,0,0-4,0v8H14a2,2,0,0,0,0,4h8v8a2,2,0,0,0,4,0V26h8a2,2,0,0,0,0-4Z"
                        fill="#0073cf"
                      />
                    </g>
                  </g>
                </svg>
                Add More
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*** EditPersonalInfoModal ***/}
      <EditPersonalInfoModal
        show={editPersonalInfoModalShow}
        onHide={() => setEditPersonalInfoModalShow(false)}
      />

      <AddCompetitorModal
        show={addCompetitorModalShow}
        onHide={() => setAddCompetitorModalShow(false)}
      />
    </>
  );
}

export default MyProfile;
