import React, { useState, useEffect } from "react";
import "../../landing.css";
import { landingPageBg } from "../../../shared/UI/styles";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../../store/auth-slice";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("token");
    }
  }, []);

  const handleBacktoSignin = () => {
    navigate("/");
  };

  const handleResetPassword = async (values, { resetForm }) => {
    if (isSubmitting || isLoading) {
      return;
    }
    setIsSubmitting(true);
    setIsLoading(true);

    const data = {
      email: values.email
    };
    try {
      const response = await dispatch(forgotPassword({ data }));

      if (response?.payload?.success) {
        setSuccessMessage(response?.payload?.success);
        resetForm();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(response?.error?.message);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row landing-wrapper">
        <div className="col-md-6 left-area d-none d-md-block" style={landingPageBg}>
          <div className="h-50 d-flex flex-column justify-content-center p-1 p-xl-5">
            <h2 className="title2 font-weight-bold color-white mb-4">
              Elevate Your Banking Analysis with The Pricing Desk –
            </h2>
            <h3 className="title3 font-weight-medium color-white">Join Us Today</h3>
          </div>
        </div>
        <div className="col-md-6 right-area d-flex flex-column justify-content-center">
          <div className="right-area-inn p-0 p-md-3">
            <h2 className="landing-main-title title2 font-weight-black color-blue text-center mb-5 pb-0 pb-sm-3">
              PRICING <span className="color-red">DESK</span>
            </h2>
            <div>
              <h2 className="title2 font-weight-black text-center">Reset Password</h2>
              <h6 className="title6 font-weight-medium text-center mb-4 mb-sm-5">
                Enter your registered email address and continue
              </h6>
              {successMessage && <div className="alert alert-success">{successMessage}</div>}
              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={handleResetPassword}>
                {({ errors, touched, isSubmitting, isLoading }) => (
                  <Form>
                    <div className="form-group">
                      <label>Email</label>
                      <Field
                        type="email"
                        name="email"
                        className={`form-control ${
                          errors.email && touched.email ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Email Address"
                      />
                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <button
                      type="submit"
                      className="fill-btn w-100"
                      disabled={isSubmitting || isLoading}>
                      Continue
                    </button>
                  </Form>
                )}
              </Formik>
              <hr className="mt-4 mb-4 mt-sm-5 mb-sm-5" />
              <button className="border-btn w-100" onClick={handleBacktoSignin}>
                Back to Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid email address")
    .required("Email is required")
});
export default ResetPassword;
