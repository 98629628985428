const URLS = {
  USER_LOGIN: "account/login/",
  USER_LOGOUT: "account/logout/",
  GET_PROFILE: "account/user-profile-detail",
  EDIT_PROFILE: "account/user-profile-edit",
  EDIT_BANK: "account/my-bank-edit/",
  GET_BANK: "account/bank-list/",
  GET_BANK_BYPARAM: "/account/bank-list",
  ADD_COMPETITOR: "account/competitor/",
  EDIT_COMPETITOR: "account/competitor",
  DELETE_COMPETITOR: "account/competitor",
  GET_DASHBOARD: "product/dashboard-detail/",
  GET_COMAPRE: "product/compare-detail",
  FORGOT_PASSWORD: "account/forgot-password/",
  RESET_PASSWORD: "account/reset-password",
  EXPORT: "product/export"
};

export default URLS;
