import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _getComapreTable } from "../data/httpService/apis";

const initialState = {
  getCompareTableData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  }
};
export const getCompareTable = createAsyncThunk("compare/fetchCompareTable", async (urlParams) => {
  try {
    return await _getComapreTable(urlParams);
  } catch (error) {
    console.error(error);
  }
});

const compareSlice = createSlice({
  name: "getCompareData",
  initialState,
  reducers: {
    resetCompareState: () => initialState
  },
  extraReducers: {
    [getCompareTable.pending]: (state) => {
      state.getCompareTableData.loading = true;
      state.getCompareTableData.response = {};
      state.getCompareTableData.hasError = false;
      state.getCompareTableData.error = {};
    },
    [getCompareTable.fulfilled]: (state, action) => {
      state.getCompareTableData.loading = false;
      state.getCompareTableData.response = action.payload;
      state.getCompareTableData.hasError = false;
      state.getCompareTableData.error = {};
    },
    [getCompareTable.rejected]: (state, action) => {
      state.getCompareTableData.loading = false;
      state.getCompareTableData.response = {};
      state.getCompareTableData.hasError = true;
      state.getCompareTableData.error = action.payload;
    }
  }
});

export const { resetCompareState } = compareSlice.actions;
export default compareSlice;
