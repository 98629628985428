import React from "react";
import "./landing.css";
import SignIn from "./components/signIn";
import { landingPageBg } from "../shared/UI/styles";

function LandingPage() {
  return (
    <div className="container-fluid">
      <div className="row landing-wrapper">
        <div className="col-md-6 left-area d-none d-md-block" style={landingPageBg}>
          <div className="h-50 d-flex flex-column justify-content-center p-1 p-xl-5">
            <h2 className="title2 font-weight-bold color-white mb-4">
              Elevate Your Banking Analysis with The Pricing Desk –
            </h2>
            <h3 className="title3 font-weight-medium color-white">Join Us Today</h3>
          </div>
        </div>
        <div className="col-md-6 right-area d-flex flex-column justify-content-center">
          <div className="right-area-inn p-0 p-md-3">
            <h2 className="landing-main-title title2 font-weight-black color-blue text-center mb-5 pb-0 pb-sm-3">
              PRICING <span className="color-red">DESK</span>
            </h2>
            <SignIn />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
