import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice, { resetAuthState } from "./auth-slice";
import profileSlice, { resetProfileState } from "./Profile-slice";
import bankSlice, { resetBankState } from "./bank-slice";
import dashboardSlice, { resetDashboardState } from "./dashboard-slice";
import compareSlice, { resetCompareState } from "./compare-slice";
import exportSlice, { resetExportState } from "./export-slice";
import tabreducer from "./tab-Slice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  bank: bankSlice.reducer,
  dashboard: dashboardSlice.reducer,
  comapre: compareSlice.reducer,
  export: exportSlice.reducer,
  tab: tabreducer
});

const persistConfig = {
  key: "pricing_desk",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

const persistor = persistStore(store);

export const clearPersist = () => {
  persistor.pause();
  persistor.flush().then(() => {
    localStorage.removeItem("token");
    storage.removeItem("persist:pricing_desk");
    return persistor.purge();
  });
};
export const resetAllStates = (dispatch) => {
  dispatch(resetAuthState());
  dispatch(resetBankState());
  dispatch(resetProfileState());
  dispatch(resetDashboardState());
  dispatch(resetCompareState());
  dispatch(resetExportState());
};
export { store, persistor };
