import React from "react";
import "./compare.css";
import CompareSearch from "../compare/components/compareSearch";

function Compare() {
  return (
    <div>
      <CompareSearch />
    </div>
  );
}

export default Compare;
