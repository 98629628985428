import React, { useEffect, useState } from "react";
import "../../landing.css";
import { landingPageBg } from "../../../shared/UI/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../../store/auth-slice";
import EyeIcon from "../../../../assets/images/visible.svg";
import EyeCloseIcon from "../../../../assets/images/hide-icon.svg";
import { toast } from "react-toastify";

function UpdatePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [id, setId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const tokens = searchParams.get("token");
    const uid = searchParams.get("uid");
    setToken(tokens);
    setId(uid);
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      new_password: values.newPassword,
      confirm_password: values.confirmPassword
    };

    await dispatch(resetPassword({ id, token, data })).then((response) => {
      if (response?.meta?.requestStatus === "fulfilled") {
        resetForm();
        setTimeout(() => {
          navigate("/password-updated");
        }, 1000);
      } else {
        toast.error("The link has been expired, Please request a new one!");
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row landing-wrapper">
        <div className="col-md-6 left-area d-none d-md-block" style={landingPageBg}>
          <div className="h-50 d-flex flex-column justify-content-center p-1 p-xl-5">
            <h2 className="title2 font-weight-bold color-white mb-4">
              Elevate Your Banking Analysis with The Pricing Desk –
            </h2>
            <h3 className="title3 font-weight-medium color-white">Join Us Today</h3>
          </div>
        </div>
        <div className="col-md-6 right-area d-flex flex-column justify-content-center">
          <div className="right-area-inn p-0 p-md-3">
            <h2 className="landing-main-title title2 font-weight-black color-blue text-center mb-5 pb-0 pb-sm-3">
              PRICING <span className="color-red">DESK</span>
            </h2>
            <div>
              <h2 className="title2 font-weight-black text-center  mb-4 mb-sm-5">
                Update Password
              </h2>
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <Form>
                  <div className="form-group ">
                    <label>New Password</label>
                    <div className="password-input">
                      <Field
                        type={!showPassword ? "password" : "text"}
                        name="newPassword"
                        className="form-control"
                        placeholder="Enter Your New Password"
                      />

                      <ErrorMessage name="newPassword" component="div" className="text-danger" />
                      <span
                        className="password-icon"
                        onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? <img src={EyeCloseIcon} /> : <img src={EyeIcon} />}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="password-input">
                      <Field
                        type={!showConfirmPassword ? "password" : "text"}
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Your New Password"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-danger"
                      />
                      <span
                        className="password-icon"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {!showConfirmPassword ? <img src={EyeCloseIcon} /> : <img src={EyeIcon} />}
                      </span>
                    </div>
                  </div>
                  <h6
                    style={{
                      fontSize: "13px",
                      fontFamily: "Arial, sans-serif",
                      end: "75px"
                    }}>
                    Password should contain at least 8 characters, one uppercase letter, one
                    lowercase letter, one digit, and one special character
                  </h6>
                  <button type="submit" className="fill-btn w-100 mt-3">
                    Update Password
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Enter valid password"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
});

export default UpdatePassword;
