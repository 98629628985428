import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import sortingIcon from "../../../../assets/images/sorting-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { getCompareTable } from "../../../../store/compare-slice";
import { fetchExportTable } from "../../../../store/export-slice";

function CertificatesDepositTable(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [formateddata, setFormateddata] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const avgRate = data?.avg_rate;
  const urlParams = `?request_for=${props.option}&cd_limit=${
    props.selected === null ? "" : props.selected
  }`;
  const dataState = useSelector((state) => state?.comapre?.getCompareTableData?.response);

  useEffect(() => {
    if (props.option === "CD") {
      dispatch(getCompareTable(urlParams)).then((response) => {
        setData(response?.payload);
      });
    }
  }, [props?.option]);

  useEffect(() => {
    if (data) {
      formattedData();
    }
  }, [data]);

  useEffect(() => {
    if (dataState) {
      setData(dataState);
    }
  }, [dataState]);

  const getColor = (value, averageRate) => {
    if (value > averageRate) {
      return "#0FB77F";
    } else if (value < averageRate) {
      return "#FF0000";
    } else {
      return "#FFA52B";
    }
  };

  const handleExport = () => {
    dispatch(fetchExportTable(urlParams))
      .then((response) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(new Blob([response.payload], { type: "text/csv" }));
        downloadLink.setAttribute("download", "CDTable.csv");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formattedData = () => {
    if (!data.bank || typeof data.bank !== "object") {
      return;
    }

    const formattedRows = Object.keys(data?.bank).map((bankName) => {
      const bankData = data?.bank[bankName];
      const bankRow = { bank_name: bankName };

      Object.keys(bankData).forEach((range) => {
        const rateData = bankData[range];
        const [start] = range.split(" - ");
        const rateKey = `${start}_rate`;
        const differenceKey = `${start}_difference`;

        bankRow[rateKey] = rateData?.rate;
        bankRow[differenceKey] = rateData?.difference;
      });
      return bankRow;
    });
    setFormateddata(formattedRows);
  };

  const handleSort = (columnno, column) => {
    const sortedData = [...formateddata];
    const sortFunction =
      sortOrder === "asc" ? (a, b) => a[column] - b[column] : (a, b) => b[column] - a[column];
    sortedData.sort(sortFunction);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setFormateddata(sortedData);
  };

  return (
    <>
      <h6 className="title6 font-weight-bold">Showing results for Certificates of Deposit</h6>
      <div className="shadow-box">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex align-items-center flex-wrap mr-0 mr-md-3">
            <div className="rate-count-area mr-3 mr-md-5 mb-4">
              <h6 className="title6 font-weight-semibold mb-0 mr-2">Top Rate</h6>
              <div className="count-box">{data?.top_rate}%</div>
            </div>
            <div className="rate-count-area mb-4">
              <h6 className="title6 font-weight-semibold mb-0 mr-2">Avg rate</h6>
              <div className="count-box">{data?.avg_rate}%</div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <button className="border-btn with-icon mb-4" onClick={handleExport}>
              <svg width="28" height="28" viewBox="0 0 32 32">
                <g id="Layer_2" data-name="Layer 2" transform="translate(-0.077 -0.077)">
                  <g id="invisible_box" data-name="invisible box">
                    <rect
                      id="Rectangle_2819"
                      data-name="Rectangle 2819"
                      width="32"
                      height="32"
                      transform="translate(0.077 0.077)"
                      fill="none"
                    />
                  </g>
                  <g id="icons_Q2" data-name="icons Q2" transform="translate(2.679 2.679)">
                    <path
                      id="Path_8237"
                      data-name="Path 8237"
                      d="M30.259,9.56,26.909,5.072A2.679,2.679,0,0,0,24.766,4H10.029A2.679,2.679,0,0,0,7.885,5.072L4.536,9.56A2.746,2.746,0,0,0,4,11.168V28.115a2.679,2.679,0,0,0,2.679,2.679H28.115a2.679,2.679,0,0,0,2.679-2.679V11.168a2.746,2.746,0,0,0-.536-1.608Zm-20.23-2.88H24.766L27.78,10.7H7.014ZM28.115,28.115H6.679V13.378h9.378v7.5l-1.742-1.742a1.273,1.273,0,0,0-2.01.134,1.407,1.407,0,0,0,.134,1.809l4.019,3.952a1.273,1.273,0,0,0,1.876,0l4.019-3.952a1.407,1.407,0,0,0,.134-1.809,1.273,1.273,0,0,0-2.01-.134L18.737,20.88v-7.5h9.378Z"
                      transform="translate(-4 -4)"
                      fill="#0073cf"
                    />
                  </g>
                </g>
              </svg>
              Export
            </button>
          </div>
        </div>
        <div className="compare-table-out">
          <Table responsive className="compare-table">
            <thead>
              <tr className="cmpr-tbl-main-head">
                <th rowSpan="3" style={{ minWidth: "300px" }}>
                  Bank Name
                </th>
                <th colSpan="10">Balance Tier</th>
              </tr>
              <tr className="cmpr-tbl-sort-head">
                {props.selected === "cd_less_than_year" && (
                  <>
                    <th colSpan="2" onClick={() => handleSort(0, "0.0_rate")}>
                      0-4 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                    <th colSpan="2" onClick={() => handleSort(0, "4.0_rate")}>
                      4-8 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                    <th colSpan="2" onClick={() => handleSort(0, "8.0_rate")}>
                      8-12 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                  </>
                )}
                {props.selected === "cd_greater_than_year" && (
                  <>
                    <th colSpan="2" onClick={() => handleSort(0, "12.0_rate")}>
                      12-16 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                    <th colSpan="2" onClick={() => handleSort(0, "16.0_rate")}>
                      16-20 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                    <th colSpan="2" onClick={() => handleSort(0, "20.0_rate")}>
                      Above 20 Months
                      <img className="pl-2" src={sortingIcon} alt="Sorting Icon" />
                    </th>
                  </>
                )}
              </tr>
              <tr className="cmpr-tbl-sml-head">
                <th style={{ minWidth: "190px" }}>Top Rate</th>
                <th style={{ minWidth: "190px" }}>Change vs Prior Week</th>
                <th style={{ minWidth: "190px" }}>Top Rate</th>
                <th style={{ minWidth: "190px" }}>Change vs Prior Week</th>
                <th style={{ minWidth: "190px" }}>Top Rate</th>
                <th style={{ minWidth: "190px" }}>Change vs Prior Week</th>
              </tr>
            </thead>
            {props.selected === "cd_less_than_year" && (
              <tbody>
                {formateddata?.map((item) => (
                  <tr key={item.bank_name}>
                    <td>{item.bank_name}</td>
                    <td style={{ color: getColor(item["0.0_rate"], avgRate) }}>
                      {item["0.0_rate"] !== undefined ? item["0.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["0.0_difference"], avgRate) }}>
                      {item["0.0_difference"] !== undefined ? item["0.0_difference"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["4.0_rate"], avgRate) }}>
                      {item["4.0_rate"] !== undefined ? item["4.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["4.0_difference"], avgRate) }}>
                      {item["4.0_difference"] !== undefined ? item["4.0_difference"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["8.0_rate"], avgRate) }}>
                      {item["8.0_rate"] !== undefined ? item["8.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["8.0_difference"], avgRate) }}>
                      {item["8.0_difference"] !== undefined ? item["8.0_difference"] + "%" : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
            {props.selected === "cd_greater_than_year" && (
              <tbody>
                {formateddata.map((item) => (
                  <tr key={item.bank_name}>
                    <td>{item.bank_name}</td>
                    <td style={{ color: getColor(item["12.0_rate"], avgRate) }}>
                      {item["12.0_rate"] !== undefined ? item["12.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["12.0_difference"], avgRate) }}>
                      {item["12.0_difference"] !== undefined ? item["12.0_difference"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["16.0_rate"], avgRate) }}>
                      {item["16.0_rate"] !== undefined ? item["16.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["16.0_difference"], avgRate) }}>
                      {item["16.0_difference"] !== undefined ? item["16.0_difference"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["20.0_rate"], avgRate) }}>
                      {item["20.0_rate"] !== undefined ? item["20.0_rate"] + "%" : "-"}
                    </td>
                    <td style={{ color: getColor(item["20.0_difference"], avgRate) }}>
                      {item["20.0_difference"] !== undefined ? item["20.0_difference"] + "%" : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex justify-content-between mt-4 flex-wrap">
          <div className="mr-3">
            <h6 className="title6 font-weight-medium color-gray">
              Total {data?.total_entries} Entries
            </h6>
          </div>
          <div>
            <ul className="rate-info mb-0">
              <li>Below avg rate</li>
              <li>Above avg rate</li>
              <li>Avg rate</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CertificatesDepositTable;
