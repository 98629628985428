import React from "react";
import "../../landing.css";
import { landingPageBg } from "../../../shared/UI/styles";
import CircleTick from "../../../../assets/images/green-circle-with-tick.svg";
import { useNavigate } from "react-router-dom";

function PasswordUpdated() {
  const navigate = useNavigate();

  const handleToLogin = () => {
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="row landing-wrapper">
        <div className="col-md-6 left-area d-none d-md-block" style={landingPageBg}>
          <div className="h-50 d-flex flex-column justify-content-center p-1 p-xl-5">
            <h2 className="title2 font-weight-bold color-white mb-4">
              Elevate Your Banking Analysis with The Pricing Desk –
            </h2>
            <h3 className="title3 font-weight-medium color-white">Join Us Today</h3>
          </div>
        </div>
        <div className="col-md-6 right-area d-flex flex-column justify-content-center">
          <div className="right-area-inn p-0 p-md-3">
            <h2 className="landing-main-title title2 font-weight-black color-blue text-center mb-5 pb-0 pb-sm-3">
              PRICING <span className="color-red">DESK</span>
            </h2>

            <div className="text-center">
              <img className="mb-4" src={CircleTick} />
              <h2 className="title2 font-weight-black text-center mb-4 mb-sm-5">
                Password Updated
              </h2>
              <button type="submit" className="fill-btn w-100" onClick={handleToLogin}>
                Go to Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordUpdated;
