import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  _getBank,
  _getBankByParam,
  _addCompetitor,
  _deleteCompetitor,
  _editCompetitor
} from "../data/httpService/apis";

const initialState = {
  getBankData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  },
  getBankByParamData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  },
  addCompititorData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  },
  deleteCompititorData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  },
  editCompititorData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  }
};

export const getBank = createAsyncThunk("bank/fetchBank", async () => {
  try {
    return await _getBank();
  } catch (error) {
    console.error(error);
  }
});

export const getBankByParam = createAsyncThunk("bank/fetchBankByPAram", async (urlParams) => {
  try {
    return await _getBankByParam(urlParams);
  } catch (error) {
    console.error(error);
  }
});

export const addCompetitor = createAsyncThunk("bank/addCompitior", async ({ data }) => {
  try {
    const response = await _addCompetitor(data);
    toast.success("Competitor Added");
    return response;
  } catch (error) {
    toast.error("Competitor Added Failed");
  }
});

export const deletCompetitor = createAsyncThunk("bank/deleteCompitior", async (competitorId) => {
  try {
    const response = await _deleteCompetitor(competitorId);
    toast.success("Competitor Deleted");
    return response;
  } catch (error) {
    toast.error("Competitor Deletion Failed");
  }
});

export const editCompetitor = createAsyncThunk("bank/editCompitior", async ({ compid, data }) => {
  try {
    return await _editCompetitor(compid, data);
  } catch (error) {
    console.error(error);
  }
});

const bankSlice = createSlice({
  name: "getBankData",
  initialState,
  reducers: {
    resetBankState: () => initialState
  },
  extraReducers: {
    [getBank.pending]: (state) => {
      state.getBankData.loading = true;
      state.getBankData.response = {};
      state.getBankData.hasError = false;
      state.getBankData.error = {};
    },
    [getBank.fulfilled]: (state, action) => {
      state.getBankData.loading = false;
      state.getBankData.response = action.payload;
      state.getBankData.hasError = false;
      state.getBankData.error = {};
    },
    [getBank.rejected]: (state, action) => {
      state.getBankData.loading = false;
      state.getBankData.response = {};
      state.getBankData.hasError = true;
      state.getBankData.error = action.payload;
    },
    [getBankByParam.pending]: (state) => {
      state.getBankByParamData.loading = true;
      state.getBankByParamData.response = {};
      state.getBankByParamData.hasError = false;
      state.getBankByParamData.error = {};
    },
    [getBankByParam.fulfilled]: (state, action) => {
      state.getBankByParamData.loading = false;
      state.getBankByParamData.response = action.payload;
      state.getBankByParamData.hasError = false;
      state.getBankByParamData.error = {};
    },
    [getBankByParam.rejected]: (state, action) => {
      state.getBankByParamData.loading = false;
      state.getBankByParamData.response = {};
      state.getBankByParamData.hasError = true;
      state.getBankByParamData.error = action.payload;
    },

    [addCompetitor.pending]: (state) => {
      state.addCompititorData.loading = true;
      state.addCompititorData.response = {};
      state.addCompititorData.hasError = false;
      state.addCompititorData.error = {};
    },
    [addCompetitor.fulfilled]: (state, action) => {
      state.addCompititorData.loading = false;
      state.addCompititorData.response = action.payload;
      state.addCompititorData.hasError = false;
      state.addCompititorData.error = {};
    },
    [addCompetitor.rejected]: (state, action) => {
      state.addCompititorData.loading = false;
      state.addCompititorData.response = {};
      state.addCompititorData.hasError = true;
      state.addCompititorData.error = action.payload;
    },

    [deletCompetitor.pending]: (state) => {
      state.deleteCompititorData.loading = true;
      state.deleteCompititorData.response = {};
      state.deleteCompititorData.hasError = false;
      state.deleteCompititorData.error = {};
    },
    [deletCompetitor.fulfilled]: (state, action) => {
      state.deleteCompititorData.loading = false;
      state.deleteCompititorData.response = action.payload;
      state.deleteCompititorData.hasError = false;
      state.deleteCompititorData.error = {};
    },
    [deletCompetitor.rejected]: (state, action) => {
      state.deleteCompititorData.loading = false;
      state.deleteCompititorData.response = {};
      state.deleteCompititorData.hasError = true;
      state.deleteCompititorData.error = action.payload;
    },

    [editCompetitor.pending]: (state) => {
      state.editCompititorData.loading = true;
      state.editCompititorData.response = {};
      state.editCompititorData.hasError = false;
      state.editCompititorData.error = {};
    },
    [editCompetitor.fulfilled]: (state, action) => {
      state.editCompititorData.loading = false;
      state.editCompititorData.response = action.payload;
      state.editCompititorData.hasError = false;
      state.editCompititorData.error = {};
    },
    [editCompetitor.rejected]: (state, action) => {
      state.editCompititorData.loading = false;
      state.editCompititorData.response = {};
      state.editCompititorData.hasError = true;
      state.editCompititorData.error = action.payload;
    }
  }
});
export const { resetBankState } = bankSlice.actions;
export default bankSlice;
