/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { _getProfile, _editProfile, _editBank } from "../data/httpService/apis";

const initialState = {
  getProfileData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  },
  updateProfileStatus: {
    loading: false,
    hasError: false,
    error: "",
    response: []
  },
  updateBank: { loading: false, response: [], hasError: false, error: {} }
};
export const getProfile = createAsyncThunk("userProfile/fetchUserProfile", async (id) => {
  try {
    return await _getProfile(id);
  } catch (error) {
    console.error(error);
  }
});
export const updateProfile = createAsyncThunk("profile/updateProfile", async ({ id, data }) => {
  try {
    const response = await _editProfile(id, data);
    toast.success("Profile updated");
    return response;
  } catch (error) {
    toast.error("Profile Update Failed");
  }
});

export const updateBank = createAsyncThunk("profile/updateBank", async ({ data }) => {
  try {
    const response = await _editBank(data);
    toast.success("Bank updated");
    return response;
  } catch (error) {
    toast.error("Bank Update Failed");
  }
});

const profileSlice = createSlice({
  name: "getProfileData",
  initialState,
  reducers: {
    resetProfileState: () => initialState
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.getProfileData.loading = true;
      state.getProfileData.response = {};
      state.getProfileData.hasError = false;
      state.getProfileData.error = {};
    },
    [getProfile.fulfilled]: (state, action) => {
      state.getProfileData.loading = false;
      state.getProfileData.response = action.payload;
      state.getProfileData.hasError = false;
      state.getProfileData.error = {};
    },
    [getProfile.rejected]: (state, action) => {
      state.getProfileData.loading = false;
      state.getProfileData.response = {};
      state.getProfileData.hasError = true;
      state.getProfileData.error = action.payload;
    },
    [updateProfile.pending]: (state) => {
      state.updateProfileStatus.loading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updateProfileStatus.loading = false;
      state.updateProfileStatus.response = action.payload;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateProfileStatus.loading = false;
      state.updateProfileStatus.hasError = true;
      state.updateProfileStatus.error = action.payload;
    },
    [updateBank.pending]: (state) => {
      state.updateBank.loading = true;
    },
    [updateBank.fulfilled]: (state, action) => {
      state.updateBank.loading = false;
    },
    [updateBank.rejected]: (state) => {
      state.updateBank.loading = false;
      state.updateBank.hasError = true;
    }
  }
});

export const { resetProfileState } = profileSlice.actions;

export default profileSlice;
