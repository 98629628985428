import React, { useState, useEffect } from "react";
import "./leftSidebar.css";
import { Link, useLocation } from "react-router-dom";
import { contentAreaLeftTopBend } from "../../../shared/UI/styles";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../../store/tab-Slice";
import LogOutModal from "./components/logOutModal";

function Leftidebar() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab);
  const location = useLocation();

  const [logOutModalShow, setlogOutModalShow] = useState(false);

  useEffect(() => {
    const storedTab = localStorage.getItem("activeTab");
    if (storedTab) {
      dispatch(setActiveTab(storedTab));
    }
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      dispatch(setActiveTab("Dashboard"));
    }
  }, [dispatch, location]);

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
    localStorage.setItem("activeTab", tab);
  };

  return (
    <div className="left-sidebar" id="left-sidebar">
      <ul className="menu ">
        <li className={activeTab === "Dashboard" ? "active" : ""}>
          <Link
            to="/dashboard"
            className={activeTab === "Dashboard" ? "active" : ""}
            onClick={() => handleTabClick("Dashboard")}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g id="Layer_2" data-name="Layer 2" transform="translate(0.433 0.433)">
                <g id="invisible_box" data-name="invisible box">
                  <rect
                    id="Rectangle_105"
                    data-name="Rectangle 105"
                    width="24"
                    height="24"
                    transform="translate(-0.433 -0.433)"
                    fill="none"
                  />
                </g>
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(1.567 1.928)">
                  <g id="Group_552" data-name="Group 552">
                    <path
                      id="Path_269"
                      data-name="Path 269"
                      d="M12.042,4H5.005A1,1,0,0,0,4,5.005v7.036a1,1,0,0,0,1.005,1.005h7.036a1,1,0,0,0,1.005-1.005V5.005A1,1,0,0,0,12.042,4Zm-1.005,7.036H6.01V6.01h5.026Z"
                      transform="translate(-4 -4)"
                      fill="#333"
                    />
                    <path
                      id="Path_270"
                      data-name="Path 270"
                      d="M34.042,4H27.005A1,1,0,0,0,26,5.005v7.036a1,1,0,0,0,1.005,1.005h7.036a1,1,0,0,0,1.005-1.005V5.005A1,1,0,0,0,34.042,4Zm-1.005,7.036H28.01V6.01h5.026Z"
                      transform="translate(-14.943 -4)"
                      fill="#333"
                    />
                    <path
                      id="Path_271"
                      data-name="Path 271"
                      d="M12.042,26H5.005A1,1,0,0,0,4,27.005v7.036a1,1,0,0,0,1.005,1.005h7.036a1,1,0,0,0,1.005-1.005V27.005A1,1,0,0,0,12.042,26Zm-1.005,7.036H6.01V28.01h5.026Z"
                      transform="translate(-4 -14.943)"
                      fill="#333"
                    />
                    <path
                      id="Path_272"
                      data-name="Path 272"
                      d="M34.042,26H27.005A1,1,0,0,0,26,27.005v7.036a1,1,0,0,0,1.005,1.005h7.036a1,1,0,0,0,1.005-1.005V27.005A1,1,0,0,0,34.042,26Zm-1.005,7.036H28.01V28.01h5.026Z"
                      transform="translate(-14.943 -14.943)"
                      fill="#333"
                    />
                  </g>
                </g>
              </g>
            </svg>
            Dashboard
          </Link>
        </li>
        <li className={activeTab === "Compare" ? "active" : ""}>
          <Link
            to="/compare"
            className={activeTab === "Compare" ? "active" : ""}
            onClick={() => handleTabClick("Compare")}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g id="Layer_2" data-name="Layer 2" transform="translate(-0.027 -0.027)">
                <g id="invisible_box" data-name="invisible box">
                  <rect
                    id="Rectangle_106"
                    data-name="Rectangle 106"
                    width="24"
                    height="24"
                    transform="translate(0.027 0.027)"
                    fill="none"
                  />
                </g>
                <g id="Q3_icons" data-name="Q3 icons" transform="translate(1.001 2.379)">
                  <path
                    id="Path_273"
                    data-name="Path 273"
                    d="M24.052,13.107,20.043,4.24h0a20.465,20.465,0,0,0-14.033,0h0L2,13.107H2v.919A3.853,3.853,0,0,0,6.009,17.7a3.853,3.853,0,0,0,4.009-3.675v-.919h0L6.811,6.216l.15-.368a18.552,18.552,0,0,1,5.062-.965V20.458H8.515a.922.922,0,1,0,0,1.838h9.021a.922.922,0,1,0,0-1.838H14.028V4.884a18.552,18.552,0,0,1,5.062.965l.15.368-3.208,6.891h0v.919A3.853,3.853,0,0,0,20.043,17.7a3.853,3.853,0,0,0,4.009-3.675ZM6.009,15.864a2.046,2.046,0,0,1-1.754-.919H7.764A2.046,2.046,0,0,1,6.009,15.864Zm2.1-2.757H3.6L6.009,8.191ZM20.043,8.191l2.1,4.916H17.637Zm0,7.672a2.046,2.046,0,0,1-1.754-.919H21.8A2.046,2.046,0,0,1,20.043,15.864Z"
                    transform="translate(-2 -3)"
                    fill="#333"
                  />
                </g>
              </g>
            </svg>
            Compare
          </Link>
        </li>
        <li>
          <Link onClick={() => setlogOutModalShow(true)}>
            <svg
              id="Layer_2"
              data-name="Layer 2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24">
              <g id="invisible_box" data-name="invisible box">
                <rect
                  id="Rectangle_109"
                  data-name="Rectangle 109"
                  width="24"
                  height="24"
                  fill="none"
                />
              </g>
              <g id="Q3_icons" data-name="Q3 icons" transform="translate(0.633 0.709)">
                <path
                  id="Path_278"
                  data-name="Path 278"
                  d="M24.2,12.573,20.147,8.467a1.078,1.078,0,0,0-1.386-.1.975.975,0,0,0-.1,1.54l2.361,2.361H9.112a1.026,1.026,0,0,0-1.026,1.026V11.238a1.026,1.026,0,0,1,1.026-1.026H16.3V3.026a1.232,1.232,0,0,0-.1-.462A1.026,1.026,0,0,0,15.271,2H13.116a11.291,11.291,0,1,0,.051,22.583h2.1a1.026,1.026,0,0,0,.719-.308l.205-.257a1.232,1.232,0,0,0,.1-.462V14.318h4.722l-2.361,2.361a.975.975,0,0,0,.1,1.54,1.078,1.078,0,0,0,1.386-.1L24.2,14.01a.975.975,0,0,0,0-1.437Z"
                  transform="translate(-1.85 -2)"
                  fill="#ff4141"
                />
              </g>
            </svg>
            Log out
          </Link>
        </li>
      </ul>
      <span className="aftr" style={contentAreaLeftTopBend}></span>

      <LogOutModal show={logOutModalShow} onHide={() => setlogOutModalShow(false)} />
    </div>
  );
}

export default Leftidebar;
