import landingBg from "../../../assets/images/landing-page-bg.jpg";
import imgUploadBg from "../../../assets/images/no-avatar.png";
import contAreaLeftTopBend from "../../../assets/images/cont-area-left-top-bend.svg";

export const landingPageBg = {
  backgroundImage: `url(${landingBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
};

export const imageUploadBg = {
  backgroundImage: `url(${imgUploadBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
};

export const contentAreaLeftTopBend = {
  backgroundImage: `url(${contAreaLeftTopBend})`,
  backgroundRepeat: "no-repeat"
};
