/* eslint-disable no-unused-vars */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { _userLogin, _userLogOut, _forgotPassword, _resetPassword } from "../data/httpService/apis";

const initialState = {
  loginStatus: { loading: false, hasError: false, error: {}, token: "", id: "", isLoggedIn: false },
  logOutStatus: { loading: false, hasError: false, error: {}, token: "" },
  forgorPasswordStatus: {
    loading: false,
    hasError: false,
    error: {},
    token: "",
    id: "",
    isLoggedIn: false
  },
  resetPasswordStatus: {
    loading: false,
    hasError: false,
    error: {},
    response: []
  }
};

export const loginAction = createAsyncThunk(
  "auth/updateAuth",
  async ({ values, callback }, { rejectWithValue }) => {
    try {
      const response = await _userLogin(values);
      if (Object.keys(response).length) {
        callback(response);
        return response;
      }
      toast.success("Login Success");
    } catch (error) {
      return rejectWithValue(error.non_field_errors);
    }
  }
);

export const logOutAction = createAsyncThunk("auth/logout", async (token) => {
  try {
    return await _userLogOut(token);
  } catch (error) {
    console.log(error);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async ({ data }) => {
  try {
    return await _forgotPassword(data);
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async ({ id, token, data }) => {
  try {
    return await _resetPassword(id, token, data);
  } catch (error) {
    console.log(error);
    throw error;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: () => initialState
  },
  extraReducers: {
    [loginAction.pending]: (state) => {
      state.loginStatus.loading = true;
    },
    [loginAction.fulfilled]: (state, action) => {
      state.token = action?.payload?.token;
      state.id = action?.payload?.id;
      state.isLoggedIn = action?.payload?.token ? true : false;
      state.loginStatus.loading = false;
    },
    [loginAction.rejected]: (state) => {
      state.loginStatus.loading = false;
    },
    [logOutAction.pending]: (state) => {
      state.logOutStatus.loading = true;
    },
    [logOutAction.fulfilled]: (state, action) => {
      state.token = "";
      state.logOutStatus.loading = false;
    },
    [logOutAction.rejected]: (state) => {
      state.logOutStatus.loading = false;
    },
    [forgotPassword.pending]: (state) => {
      state.forgorPasswordStatus.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.forgorPasswordStatus.loading = false;
    },
    [forgotPassword.rejected]: (state) => {
      state.forgorPasswordStatus.loading = false;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordStatus.loading = true;
      state.resetPasswordStatus.response = {};
      state.resetPasswordStatus.hasError = false;
      state.resetPasswordStatus.error = {};
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetPasswordStatus.loading = false;
      state.resetPasswordStatus.response = action.payload;
      state.resetPasswordStatus.hasError = false;
      state.resetPasswordStatus.error = {};
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetPasswordStatus.loading = false;
      state.resetPasswordStatus.response = {};
      state.resetPasswordStatus.hasError = true;
      state.resetPasswordStatus.error = action;
    }
  }
});

export default authSlice;

export const { resetAuthState } = authSlice.actions;
