import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { addCompetitor, getBankByParam } from "../../../../store/bank-slice";
import { getProfile } from "../../../../store/Profile-slice";

function AddCompetitorModal(props) {
  const dispatch = useDispatch();
  const bankByParam = useSelector((state) => state?.bank?.getBankByParamData);
  const { id } = useSelector((state) => state?.auth);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const urlParams = "?request_for=competitor";

  useEffect(() => {
    getProfile(id);
    dispatch(getBankByParam(urlParams));
  }, [dispatch, urlParams]);

  const handleAddCompetitor = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const data = {
      bank: values.bank,
      weightage: values.weightage
    };
    try {
      await dispatch(addCompetitor({ data }));
      await dispatch(getProfile(id));
      props.onHide();
    } catch (error) {
      console.error("Error adding competitor: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    props.onHide();
    dispatch(getProfile(id));
  };

  const validate = (values) => {
    const errors = {};

    if (!values.weightage) {
      errors.weightage = "Weighting is Required";
    }
    if (!values.bank) {
      errors.bank = "Competitor is Required";
    }

    return errors;
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-normal"
        backdrop="static"
        keyboard={false}
        onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Add Competitor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              weightage: "",
              bank: ""
            }}
            validate={validate}
            onSubmit={handleAddCompetitor}>
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Competitor</label>
                  <select
                    aria-label="Default select example"
                    className="w-100"
                    onChange={handleChange}
                    name="bank"
                    value={values.bank}>
                    <option disabled value="">
                      Select competitor
                    </option>
                    {bankByParam?.response.map((bankItem) => (
                      <option key={bankItem.id} value={bankItem.id}>
                        {bankItem.bank_name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="bank"
                    component="div"
                    className="error-message"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="form-group">
                  <label>Weighting</label>
                  <Field
                    as="select"
                    className="form-control"
                    name="weightage"
                    value={values.weightage}
                    onChange={handleChange}>
                    <option value="">Select Weighting</option>
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                  </Field>
                  <ErrorMessage
                    name="weightage"
                    component="div"
                    className="error-message"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="pt-3 d-flex justify-content-end">
                  <button type="button" className="fill-btn btn-red mr-3" onClick={props.onHide}>
                    Cancel
                  </button>
                  <button type="submit" className="fill-btn" disabled={isSubmitting}>
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddCompetitorModal;
