/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";
import { imageUploadBg } from "../../../shared/UI/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile, getProfile } from "../../../../store/Profile-slice";

function EditPersonalInfoModal(props) {
  const dispatch = useDispatch();
  const getProfileData = useSelector((state) => state?.profile?.getProfileData);
  const { id } = useSelector((state) => state?.auth);
  const [profilePicFile, setProfilePicFile] = useState(null);

  function getImageFileObject(imageFile) {
    setProfilePicFile(imageFile.file);
  }
  function runAfterImageDelete(file) {
    console.log({ file });
  }
  const handleUpdateProfile = (values, onSubmitProps) => {
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("first_name", values.firstname);
    formData.append("last_name", values.lastname);
    formData.append("username", getProfileData?.response?.username);
    formData.append("location", values.location);
    if (profilePicFile) {
      formData.append("profile_pic", profilePicFile);
    }

    dispatch(updateProfile({ id, data: formData })).then(() => {
      dispatch(getProfile(id));
    });
    onSubmitProps.setSubmitting(false);

    props.onHide();
  };
  const handleCancel = (resetForm) => {
    resetForm();
    props.onHide();
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-normal"
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Edit Personal Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              firstname: getProfileData?.response?.first_name || "",
              lastname: getProfileData?.response?.last_name || "",
              location: getProfileData?.response?.location || "",
              email: getProfileData?.response?.email || "",
              username: getProfileData?.response?.username || "",
              profile_pic: profilePicFile
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateProfile}>
            {({ values, handleSubmit, handleChange, handleBlur, errors, touched, resetForm }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="img-upload-area d-flex justify-content-center mb-5">
                  <ImageUploader
                    style={imageUploadBg}
                    onFileAdded={(img) => getImageFileObject(img)}
                    onFileRemoved={(img) => runAfterImageDelete(img)}
                  />
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className="error"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error"
                    style={{ color: "red" }}
                  />
                </div>
                <div className="form-group">
                  <label>Location</label>
                  <Field type="text" name="location" className="form-control" />
                  <ErrorMessage name="location" component="div" style={{ color: "red" }} />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    type="text"
                    name="email"
                    className={`form-control ${errors.email && touched.email ? "is-invalid" : ""}`}
                  />
                  {errors.email && touched.email && (
                    <div className="error-message" style={{ color: "red" }}>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="pt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="fill-btn btn-red mr-3"
                    onClick={() => handleCancel(resetForm)}>
                    Cancel
                  </button>
                  <button type="submit" className="fill-btn">
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is Required"),
  lastname: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, "Enter a valid email address")
    .required("Email is Required"),
  location: Yup.string().required("Location is Required")
});
export default EditPersonalInfoModal;
