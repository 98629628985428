import API from "../config";
import URLS from "../../constants/urls";

export const _userLogin = async (data) => {
  return API.post(URLS.USER_LOGIN, data);
};
export const _forgotPassword = async (data) => {
  return API.post(URLS.FORGOT_PASSWORD, data);
};
export const _resetPassword = async (id, token, data) => {
  return API.post(`${URLS.RESET_PASSWORD}/${id}/${token}/`, data);
};

export const _getProfile = async (id) => {
  return API.get(`${URLS.GET_PROFILE}/${id}/`);
};
export const _editProfile = async (id, data) => {
  return API.put(`${URLS.EDIT_PROFILE}/${id}/`, data, {
    headers: {
      ...API.defaults.headers,
      "Content-Type": "multipart/form-data"
    }
  });
};
export const _getBank = async () => {
  return API.get(URLS.GET_BANK);
};
export const _getBankByParam = async (urlParams) => {
  return API.get(`${URLS.GET_BANK_BYPARAM}/${urlParams}`);
};

export const _editBank = async (data) => {
  return API.put(URLS.EDIT_BANK, data);
};
export const _addCompetitor = async (data) => {
  return API.post(URLS.ADD_COMPETITOR, data);
};
export const _editCompetitor = async (id, data) => {
  return API.put(`${URLS.EDIT_COMPETITOR}/${id}/`, data);
};
export const _deleteCompetitor = async (id) => {
  return API.delete(`${URLS.DELETE_COMPETITOR}/${id}/`);
};
export const _userLogOut = async (token) => {
  const headers = {
    Authorization: `token ${token}`
  };

  return API.post(URLS.USER_LOGOUT, {}, { headers });
};

export const _getDashboard = async () => {
  return API.get(URLS.GET_DASHBOARD);
};
export const _getComapreTable = async (urlParams) => {
  return API.get(`${URLS.GET_COMAPRE}/${urlParams}`);
};
export const _exportTable = async (urlParams) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `token ${token}`
  };
  return API.get(`${URLS.EXPORT}/${urlParams}`, { headers });
};
