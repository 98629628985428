import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { _exportTable } from "../data/httpService/apis";

const initialState = {
  exportStatus: {
    loading: false,
    hasError: false,
    error: {},
    response: []
  }
};

export const fetchExportTable = createAsyncThunk("export/fetchExportTable", async (urlParams) => {
  try {
    return await _exportTable(urlParams);
  } catch (error) {
    console.error(error);
  }
});

const exportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    resetExportState: () => initialState
  },
  extraReducers: {
    [fetchExportTable.pending]: (state) => {
      state.exportStatus.loading = true;
      state.exportStatus.response = {};
      state.exportStatus.hasError = false;
      state.exportStatus.error = {};
    },
    [fetchExportTable.fulfilled]: (state, action) => {
      state.exportStatus.loading = false;
      state.exportStatus.response = action.payload;
      state.exportStatus.hasError = false;
      state.exportStatus.error = {};
    },
    [fetchExportTable.rejected]: (state, action) => {
      state.exportStatus.loading = false;
      state.exportStatus.response = {};
      state.exportStatus.hasError = true;
      state.exportStatus.error = action.payload;
    }
  }
});

export const { resetExportState } = exportSlice.actions;
export default exportSlice;
