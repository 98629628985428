import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { _getDashboard } from "../data/httpService/apis";

const initialState = {
  getDashboardData: {
    loading: false,
    response: [],
    hasError: false,
    error: ""
  }
};

export const getDashboard = createAsyncThunk("dashboard/getdashboard", async () => {
  try {
    return await _getDashboard();
  } catch (error) {
    console.error(error);
  }
});

const dashboardSlice = createSlice({
  name: "getDashboardData",
  initialState,
  reducers: {
    resetDashboardState: () => initialState
  },
  extraReducers: {
    [getDashboard.pending]: (state) => {
      state.getDashboardData.loading = true;
      state.getDashboardData.response = {};
      state.getDashboardData.hasError = false;
      state.getDashboardData.error = {};
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.getDashboardData.loading = false;
      state.getDashboardData.response = action.payload;
      state.getDashboardData.hasError = false;
      state.getDashboardData.error = {};
    },
    [getDashboard.rejected]: (state, action) => {
      state.getDashboardData.loading = false;
      state.getDashboardData.response = {};
      state.getDashboardData.hasError = true;
      state.getDashboardData.error = action.payload;
    }
  }
});

export const { resetDashboardState } = dashboardSlice.actions;

export default dashboardSlice;
