import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { loginAction } from "../../../../store/auth-slice";
import EyeIcon from "../../../../assets/images/visible.svg";
import EyeCloseIcon from "../../../../assets/images/hide-icon.svg";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordFieldTouched, setPasswordFieldTouched] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleLoginSubmit = (values, onSubmitProps) => {
    if (isLoggingIn) {
      return;
    }
    setIsLoggingIn(true);
    const data = {
      values: values,
      callback: function (response) {
        if (response) {
          localStorage.setItem("token", response.token);
          toast.success("Logged in successfully");
          navigate("/dashboard");
          onSubmitProps.resetForm();
        } else {
          toast.error("Login Failed");
        }
        setIsLoggingIn(false);
      }
    };

    dispatch(loginAction(data)).then((response) => {
      if (response?.error) {
        const errorMessages = response?.payload?.map((error) => error);
        toast.error(errorMessages.join("\n"));
      }
    });
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <div>
      <h2 className="title2 font-weight-black text-center mb-4 mb-sm-5">Sign In</h2>
      <Formik
        validationSchema={SignInSchema}
        onSubmit={handleLoginSubmit}
        initialValues={{
          username: "",
          password: ""
        }}>
        {({ values, errors, handleSubmit, handleChange, handleBlur, touched }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Username</label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                className="input-item"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.username}
                autoComplete="username"
              />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </div>
            <div className="form-group ">
              <label>Password</label>
              <div className="password-input">
                <Form.Control
                  type={!showPassword ? "password" : "text"}
                  placeholder="Enter Password"
                  className="input-item"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.password && (touched.password || passwordFieldTouched)}
                  autoComplete="current-password"
                  onFocus={() => setPasswordFieldTouched(true)}
                />

                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                <span className="password-icon" onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? <img src={EyeCloseIcon} /> : <img src={EyeIcon} />}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap mb-3">
              <div className="checkbox-area form-group mb-2">
                <input type="checkbox" id="html" />
                <label htmlFor="html">Remember Me</label>
              </div>
              <a className="link-btn mb-2" onClick={handleForgotPassword}>
                Forgot Password?
              </a>
            </div>
            <button type="submit" className="fill-btn w-100">
              Sign In
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const SignInSchema = yup.object().shape({
  password: yup.string().required("Password is Required"),
  username: yup.string().required("Username is Required ")
});

export default SignIn;
