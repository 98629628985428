import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "../modules/dashboard";
import Compare from "../modules/compare";
import MyProfile from "../modules/myProfile";
import LandingPage from "../modules/landingPage";
import ProtectedRouter from "./ProtectedRouter";
import PageWrapper from "../layout/pagewrapper";
import ResetPassword from "../modules/landingPage/components/resetPassword";
import UpdatePassword from "../modules/landingPage/components/updatePassword";
import PasswordUpdated from "../modules/landingPage/components/passwordUpdated";

function Routers() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/update-password" element={<UpdatePassword />} />
          <Route exact path="/password-updated" element={<PasswordUpdated />} />

          <Route element={<ProtectedRouter layout={PageWrapper} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/myprofile" element={<MyProfile />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default Routers;
