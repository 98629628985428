import axios from "axios";
import { toast } from "react-toastify";

// const BASE_URL = "https://pricing-desk.sayone.team/";

const BASE_URL = "https://pricing-desk-dev.sayone.team/";

const API = axios.create({
  baseURL: BASE_URL, // global url
  timeout: 20000,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json"
  }
});

const getUser = () => {
  return localStorage.getItem("token");
};

const setLogOut = () => {
  localStorage.setItem("token", "");
};

const handleRequest = async (req) => {
  if (req.url == "account/login/") {
    setLogOut();
  }
  const token = getUser();
  if (token) req.headers.Authorization = `Token ${token}`;
  return req;
};

const handleError = (error) => {
  if (error?.response?.status === "401") {
    toast.warn("Session Expired! Login again");
    setLogOut();
    window.location.href("/");
  } else if (error?.response?.status == "403") {
    toast.warn("Access Denied");
  }
  const parsed_error = Object.assign({}, error);
  if (parsed_error.code == "ECONNABORTED" && !parsed_error.config.__isRetryRequest) {
    parsed_error.config.__isRetryRequest = true;
    return API.request(parsed_error.config);
  }
  return Promise.reject(parsed_error?.response?.data);
};

const handleResponse = (response) => {
  return Promise.resolve(response.data);
};

API.interceptors.request.use(handleRequest);
API.interceptors.response.use(handleResponse, handleError);

export default API;
