import { createSlice } from "@reduxjs/toolkit";

const tabSlice = createSlice({
  name: "tab",
  initialState: "dashboard",
  reducers: {
    setActiveTab: (state, action) => {
      return action.payload;
    }
  }
});

export const { setActiveTab } = tabSlice.actions;

export default tabSlice.reducer;
