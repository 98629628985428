import React from "react";

function ContentArea(props) {
  return (
    <div className="content-area">
      <div className="content-area-inner">
        <div className="page-contents">{props.children}</div>
      </div>
    </div>
  );
}

export default ContentArea;
