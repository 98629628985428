import React, { useEffect } from "react";
import "./header.css";
import profImg from "../../../../assets/images/no-avatar.png";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "../../../../store/Profile-slice";
import { setActiveTab } from "../../../../store/tab-Slice";

function Header() {
  const dispatch = useDispatch();
  const getProfileData = useSelector((state) => state?.profile?.getProfileData);
  const { id } = useSelector((state) => state?.auth);
  const activeTab = useSelector((state) => state.tab);

  useEffect(() => {
    dispatch(getProfile(id));
  }, [dispatch]);

  const handleProfileClick = () => {
    dispatch(setActiveTab("My Profile"));
  };

  return (
    <header>
      <div className="logo-area">
        <h4 className="title4 font-weight-black color-blue mb-0">LOGO</h4>
      </div>
      <div className="d-flex flex-fill justify-content-between align-items-center flex-wrap flex-sm-nowrap">
        <div
          className="toggle-btn mr-0 mr-sm-3"
          onClick={() => {
            document.getElementById("left-sidebar").classList.toggle("active");
          }}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h3 className="title3 head mb-0 mr-0 mr-sm-3">{activeTab}</h3>
        {/* Display dynamic header title */}
        <div className="d-flex align-items-center flex-fill justify-content-between justify-content-sm-end  mb-3 mb-sm-0">
          {/*  <Form.Select aria-label="Default select example" className="mr-3 mr-xl-5">
            <option disabled selected>
              Select region
            </option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        */}
          <Link to="/myprofile" className="head-profile" onClick={handleProfileClick}>
            <div className="prof-img">
              {getProfileData?.response?.profile_pic ? (
                <img src={getProfileData?.response?.profile_pic} />
              ) : (
                <img src={profImg} alt="Profile" />
              )}
            </div>
            <span className="prof-nm">
              {getProfileData?.response?.first_name
                ? getProfileData.response.first_name.charAt(0).toUpperCase() +
                  getProfileData.response.first_name.slice(1)
                : ""}{" "}
              {getProfileData?.response?.last_name}
            </span>
          </Link>
          <Link to="/dashboard" className="head-profile"></Link>
          <Link to="/compare" className="head-profile"></Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
